<script setup>
import LigneModeleAim from '@/components/service/modele-aim/ligne-poste-modele-aim.vue'
import LigneAjoutPosteModeleAim from '@/components/service/modele-aim/ligne-ajout-poste-modele-aim.vue'
import DisclamerModeleAIM from '@/components/service/modele-aim/disclamerModeleAIM.vue'
import SInfoMontant from '@/components/global/s-info-montant.vue'
</script>

<template>
  <div>
    <disclamer-modele-AIM></disclamer-modele-AIM>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left fixed-width">Poste</th>
            <th class="text-right fixed-width">Budget par défaut</th>
            <th class="text-left fixed-width">Périodicité</th>
            <th class="modele-table-aim-actions">Modifier</th>
          </tr>
        </thead>
        <tbody>
          <v-fade-transition>
            <td colspan="4" v-show="loadingModeleAIM">
              <v-progress-linear color="primary" :indeterminate="loadingModeleAIM"></v-progress-linear>
            </td>
          </v-fade-transition>
          <ligne-modele-aim
            v-for="ligneModeleAIM in sortedLignesModeleAIM"
            :key="ligneModeleAIM.posteAIM.posteAIMId"
            :postes-non-definits="postesNonDefinits"
            :ligne-modele-a-i-m="ligneModeleAIM"
            :loaded="existeDansPosteModeleAIM(ligneModeleAIM.posteAIM)"
            :disabled="loadingModeleAIM"
            :can-order-up="ligneModeleAIM.ordre > 1"
            :can-order-down="ligneModeleAIM.ordre < listePostesAIMPrevus.length"
            @update="(nouvelleLigne) => handleUpdate(ligneModeleAIM, nouvelleLigne)"
            @delete="(LigneASupprimer) => handleDelete(LigneASupprimer)"
            @order-up="handleOrderUp(ligneModeleAIM)"
            @order-down="handleOrderDown(ligneModeleAIM)"
          ></ligne-modele-aim>
          <tr class="ligne-total">
            <td>
              <strong>TOTAL</strong>
              <template v-if="sontBudgetMinEtMaxDifferents">
                <small> (varie selon le nombre de jours dans le mois)</small>
              </template>
            </td>
            <td class="text-right">
              <span v-if="sontBudgetMinEtMaxDifferents">
                <s-info-montant
                  :afficher-devise="false"
                  :montant="modeleAimActuel.totalBudgetMensuelMinimal"
                ></s-info-montant>
                -
              </span>
              <s-info-montant :montant="modeleAimActuel.totalBudgetMensuelMaximal"></s-info-montant>
            </td>
            <td>par mois</td>
            <td></td>
          </tr>
          <v-fade-transition>
            <ligne-ajout-poste-modele-aim
              v-show="postesNonDefinits.length > 0"
              :postes-non-definits="postesNonDefinits"
              @add="(nouvelleLigne) => handleAdd(nouvelleLigne)"
            ></ligne-ajout-poste-modele-aim>
          </v-fade-transition>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    modeleAimActuel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      postesDisponibles: 'posteAIM/getPostes',
    }),
    sortedLignesModeleAIM() {
      return this.listePostesAIMPrevus.sort((a, b) => a.ordre > b.ordre)
    },
    postesNonDefinits() {
      return this.postesDisponibles.filter((posteAIM) => !this.existeDansPosteModeleAIM(posteAIM))
    },
    serviceId() {
      return this.$route.params.serviceId
    },
    sontBudgetMinEtMaxDifferents() {
      return this.modeleAimActuel.totalBudgetMensuelMinimal !== this.modeleAimActuel.totalBudgetMensuelMaximal
    },
  },
  watch: {
    // whenever question changes, this function will run
    serviceId: function () {},
  },
  methods: {
    ...mapActions({
      fetchPostes: 'posteAIM/fetchPostes',
      modeliserLesPostesAIM: 'service/modeliserLesPostesAIM',
      placerModelePosteAIMAPosition: 'service/placerModelePosteAIMAPosition',
    }),
    handleUpdate(ancienneLigne, nouvelleLigne) {
      this.listePostesAIMPrevus.splice(
        this.listePostesAIMPrevus.findIndex((ligne) => ligne.posteAIM.posteAIMId === ancienneLigne.posteAIM.posteAIMId),
        1,
        nouvelleLigne
      )
      this.envoyerModificationModeleAIM()
    },
    existeDansPosteModeleAIM(posteAIM) {
      return this.listePostesAIMPrevus.some((ligne) => ligne.posteAIM.posteAIMId === posteAIM.posteAIMId)
    },
    handleAdd(nouvelleLigne) {
      this.listePostesAIMPrevus.push(nouvelleLigne)
      this.envoyerModificationModeleAIM()
    },
    handleDelete(LigneASupprimer) {
      this.listePostesAIMPrevus.splice(
        this.listePostesAIMPrevus.findIndex(
          (ligne) => ligne.posteAIM.posteAIMId === LigneASupprimer.posteAIM.posteAIMId
        ),
        1
      )
      this.envoyerModificationModeleAIM()
    },
    handleOrderUp(ligneModeleAIM) {
      this.loadingModeleAIM = true
      if (ligneModeleAIM.ordre > 1) {
        this.placerModelePosteAIMAPosition({
          serviceId: this.serviceId,
          modelePosteAIMId: ligneModeleAIM.modelePosteAIMId,
          positionDemande: ligneModeleAIM.ordre - 1,
        }).then((value) => {
          this.listePostesAIMPrevus = value.modeleAIM.postesAIMPrevus
          this.loadingModeleAIM = false
        })
      }
    },
    handleOrderDown(ligneModeleAIM) {
      this.loadingModeleAIM = true
      if (ligneModeleAIM.ordre < this.listePostesAIMPrevus.length) {
        this.placerModelePosteAIMAPosition({
          serviceId: this.serviceId,
          modelePosteAIMId: ligneModeleAIM.modelePosteAIMId,
          positionDemande: ligneModeleAIM.ordre + 1,
        }).then((value) => {
          this.listePostesAIMPrevus = value.modeleAIM.postesAIMPrevus
          this.loadingModeleAIM = false
        })
      }
    },
    envoyerModificationModeleAIM() {
      this.loadingModeleAIM = true
      this.modeliserLesPostesAIM({
        serviceId: this.serviceId,
        listePostesAIMPrevus: this.sortedLignesModeleAIM,
      }).then((value) => {
        this.listePostesAIMPrevus = value.modeleAIM.postesAIMPrevus
        this.loadingModeleAIM = false
      })
    },
  },
  mounted() {
    this.fetchPostes()
    this.listePostesAIMPrevus = JSON.parse(JSON.stringify(this.modeleAimActuel.postesAIMPrevus))
    this.loadingModeleAIM = false
  },
  data() {
    return {
      listePostesAIMPrevus: [],
      loadingModeleAIM: true,
    }
  },
}
</script>

<style scoped>
.fixed-width {
  max-width: 30vh;
}

.modele-table-aim-actions {
  max-width: 6vh !important;
}

.ligne-total {
  background-color: #dedede;
}
</style>
