import axios from 'axios'

export default {
  namespaced: true,
  state: {
    fichesAim: [],
  },
  mutations: {
    SET_FICHES_AIMS(state, _fichesAim) {
      state.fichesAim = _fichesAim
    },
  },
  getters: {
    getFicheAimByUsagerId: (state) => (_usagerId) => {
      return state.fichesAim.filter((ficheAim) => ficheAim.usager.usagerId === _usagerId)
    },
  },
  actions: {
    fetchFicheAimsUsagerId({ commit }, _usagerId) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/ficheAIM/usager/` + _usagerId).then((response) => {
        commit('SET_FICHES_AIMS', response.data)
      })
    },
  },
}
