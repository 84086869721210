<template>
  <v-list-item :to="{ name: 'etablissement', params: { etablissementId: etablissement.etablissementId } }">
    <v-list-item-content>
      <v-list-item-title v-text="etablissement.raisonSociale" />
      <v-list-item-subtitle v-text="'FINESS: ' + etablissement.numeroFINESS" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'etablissement-list-item',
  props: {
    etablissement: {
      type: Object,
      required: true,
    },
  },
}
</script>
