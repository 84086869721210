<script setup>
import { computed } from 'vue'

const props = defineProps({
  montant: {
    type: Number,
    required: true,
  },
  afficherDevise: {
    type: Boolean,
    default: true,
  },
})

const formatFrancais = new Intl.NumberFormat('fr-FR', {
  style: props.afficherDevise ? 'currency' : 'decimal',
  currency: 'EUR',
})

const montantFormate = computed(() => {
  return formatFrancais.format(props.montant)
})
</script>

<template>
  <span>{{ montantFormate }}</span>
</template>
