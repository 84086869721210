<template>
  <s-main-panel v-if="loaded">
    <v-container class="fixed-height">
      <v-card class="fixed-height">
        <v-toolbar color="white" flat>
          <v-toolbar-title>{{ service.nom }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <template
            v-if="
              serviceId !== 0 &&
              (isInRole('CHEF_DE_SERVICE_' + serviceId) ||
                isInRole('COMPTABLE_' + serviceId) ||
                isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId))
            "
          >
            <v-btn color="info" outlined raised class="mr-2" @click="showExportPayeDialog = true">
              <v-icon small class="mr-2">fa-file-export</v-icon>
              Export Paye
            </v-btn>
            <service-export-paye-dialog v-model="showExportPayeDialog" :service="service" />
          </template>
          <!-- On vérifie que ce ne soit pas un service généré (sans planning) -->
          <v-btn
            v-if="serviceId !== 0"
            class="mr-2"
            color="primary"
            dark
            :to="{
              name: 'service-planning',
              params: { serviceId: serviceId },
            }"
          >
            <v-icon small class="mr-2">fa-calendar-alt</v-icon>
            Planning
          </v-btn>
          <template v-slot:extension>
            <v-tabs v-model="tab">
              <v-tabs-slider color="secondary" />
              <v-tab key="Membres">
                Membres
                <membre-create-dialog
                  v-if="
                    tab === 0 &&
                    serviceId !== 0 &&
                    (isInRole('CHEF_DE_SERVICE_' + serviceId) ||
                      isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId))
                  "
                  :service="service"
                />
              </v-tab>
              <v-tab v-if="serviceId !== 0" key="Tableau des congés"> Tableau des congés </v-tab>
              <v-tab
                v-if="
                  serviceId !== 0 &&
                  (isInRole('CHEF_DE_SERVICE_' + serviceId) ||
                    isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId))
                "
                key="Administration"
              >
                Administration
              </v-tab>
              <v-tab
                v-if="
                  false &&
                  serviceId !== 0 &&
                  (isInRole('CHEF_DE_SERVICE_' + serviceId) ||
                    isInRole('ADMINISTRATEUR' + service.etablissement.etablissementId))
                "
                key="AIM"
              >
                Aide Indiv. Mensuelle
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab" class="fixed-height">
          <v-tab-item key="Membres" class="fixed-height">
            <v-card flat class="fixed-height">
              <v-list class="pb-0 overflow-y-auto" style="max-height: 100%; overflow: hidden">
                <v-list class="py-0" subheader>
                  <v-subheader v-if="service.serviceId !== 0">Titulaires</v-subheader>
                  <template v-for="(membre, index) in membresTitulaires">
                    <membre-list-item :membre="membre" :key="index + 1" />
                    <v-divider v-if="index < membresTitulaires.length - 1" :key="-(index + 1)"></v-divider>
                  </template>
                </v-list>
                <v-list
                  v-if="service.serviceId !== 0 && membreRemplacants.length > 0"
                  class="py-0"
                  subheader
                  color="grey lighten-3"
                >
                  <v-subheader>Remplacants</v-subheader>
                  <template v-for="(membre, index) in membreRemplacants">
                    <membre-list-item :key="index + 1" :membre="membre" />
                    <v-divider v-if="index < membreRemplacants.length - 1" :key="-(index + 1)"></v-divider>
                  </template>
                </v-list>
              </v-list>
            </v-card>
          </v-tab-item>
          <v-tab-item key="Tableau des congés" class="fixed-height">
            <conges-apercu :etablissementId="service.etablissement.etablissementId" :serviceId="service.serviceId" />
          </v-tab-item>
          <v-tab-item
            v-if="
              serviceId !== 0 &&
              (isInRole('CHEF_DE_SERVICE_' + serviceId) ||
                isInRole('ADMINISTRATEUR_' + service.etablissement.etablissementId))
            "
            key="Administration"
            class="fixed-height"
          >
            <service-detail-administration :service="service" />
          </v-tab-item>
          <v-tab-item key="AIM" class="fixed-height">
            <service-detail-modele-aim :modele-aim-actuel="service.modeleAIM"></service-detail-modele-aim>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </s-main-panel>
</template>

<script>
import MembreCreateDialog from '../../components/membre/membre-create-dialog'
import ServiceExportPayeDialog from '../../components/service/service-export-paye-dialog'
import ServiceDetailAdministration from './administration/service-detail-administration.vue'
import MembreListItem from '../../components/membre/membre-list-item'
import CongesApercu from '../../components/conges/apercu/conges-apercu'
import { mapGetters } from 'vuex'
import SMainPanel from '@/components/global/s-main-panel.vue'
import ServiceDetailModeleAim from '@/components/service/modele-aim/service-detail-modele-aim.vue'

export default {
  name: 'service-detail',
  components: {
    ServiceDetailModeleAim,
    SMainPanel,
    MembreListItem,
    MembreCreateDialog,
    ServiceExportPayeDialog,
    ServiceDetailAdministration,
    CongesApercu,
  },
  data() {
    return {
      tab: 0,
      showServiceUpdateDialog: false,
      showExportPayeDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      getServiceById: 'service/getServiceById',
      getTitulairesByServiceId: 'membre/getTitulairesByServiceId',
      getRemplacantsByServiceId: 'membre/getRemplacantsByServiceId',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return this.service
    },
    serviceId() {
      return parseInt(this.$route.params.serviceId, 10)
    },
    service() {
      return this.getServiceById(this.serviceId)
    },
    membresTitulaires() {
      return this.getTitulairesByServiceId(this.serviceId)
    },
    membreRemplacants() {
      return this.getRemplacantsByServiceId(this.serviceId)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  display: flex;
  flex-direction: column;
}
</style>
