import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa', // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    primary: process.env.VUE_APP_ENV_NAME !== 'prod' ? colors.teal : colors.indigo,
    secondary: colors.amber,
    accent: colors.cyan,
    error: colors.red,
    warning: colors.orange,
    info: colors.blue,
    success: colors.green,
    themes: {
      light: {
        primary: process.env.VUE_APP_ENV_NAME !== 'prod' ? colors.teal : colors.indigo,
        secondary: colors.amber,
        accent: colors.cyan,
        error: colors.red,
        warning: colors.orange,
        info: colors.blue,
        success: colors.green,
      },
    },
  },
})
