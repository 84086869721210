<template>
  <v-card class="ma-3 mt-0 fixed-height" flat v-if="!loading">
    <v-toolbar flat dense class="contrats-toolbar">
      <v-toolbar-title class="text-h6"> Fiches d'Aide Individuelles Mensuelles </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container class="fixed-height pa-0">
      <perfect-scrollbar>
        <v-list two-line class="pa-0">
          <fiche-aim-usager-list-item
            v-for="ficheAim in fichesAimUsager"
            :key="ficheAim.ficheAimId"
            :fiche-aim="ficheAim"
          ></fiche-aim-usager-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FicheAimUsagerListItem from '@/components/usager/fiche-aim-usager-list-item.vue'

export default {
  name: 'historique-accueil-usager',
  components: { FicheAimUsagerListItem },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      getAccueilByUsagerId: 'ficheAIM/getFicheAimByUsagerId',
    }),
    usagerId() {
      return parseInt(this.$route.params.usagerId)
    },
    fichesAimUsager() {
      return this.getAccueilByUsagerId(this.usagerId).sort((a, b) => {
        if (a.annee !== b.annee) return a.annee < b.annee
        return a.mois < b.mois
      })
    },
  },
  methods: {
    ...mapActions({
      fetchFichesAimByUsager: 'ficheAIM/fetchFicheAimsUsagerId',
    }),
  },
  mounted() {
    this.fetchFichesAimByUsager(this.usagerId).then(() => (this.loading = false))
  },
}
</script>

<style scoped>
.contrats-toolbar {
  background-color: var(--v-primary-lighten3) !important;
}

.fixed-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  display: flex;
  flex-direction: column;
}
</style>
