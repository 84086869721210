<template>
  <v-list-item :to="{ name: 'fiche-aim', params: { ficheAimId: ficheAim.ficheAIMId } }">
    <v-list-item-content>
      <v-list-item-title>Fiche pour {{ monthLabel(ficheAim.mois) }} {{ ficheAim.annee }}</v-list-item-title>
      <v-list-item-subtitle> {{ ficheAim.lignes.length }} postes définits </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-chip label :color="chipColor">
        <v-icon left> {{ chipIcon }} </v-icon>
        {{ chipStatus }}</v-chip
      >
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'fiche-aim-usager-list-item',
  props: {
    ficheAim: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      monthLabel: 'calendrier/getMonthLabel',
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    chipColor() {
      switch (this.ficheAim.statut) {
        case 'EN_PREPARATION':
          return 'warning'
        case 'EN_ATTENTE_DE_VALIDATION':
          return 'info'
        case 'VALIDEE':
          return 'success'
        default:
          console.error('Illegal state : Le statut de la fiche ne correspon à aucun statut prévus')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    chipIcon() {
      switch (this.ficheAim.statut) {
        case 'EN_PREPARATION':
          return 'mdi-progress-wrench'
        case 'EN_ATTENTE_DE_VALIDATION':
          return 'mdi-progress-question'
        case 'VALIDEE':
          return 'mdi-check-circle-outline'
        default:
          console.error('Illegal state : Le statut de la fiche ne correspon à aucun statut prévus')
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    chipStatus() {
      switch (this.ficheAim.statut) {
        case 'EN_PREPARATION':
          return 'En préparation'
        case 'EN_ATTENTE_DE_VALIDATION':
          return 'En attente de validation'
        case 'VALIDEE':
          return 'Validée'
        default:
          console.error('Illegal state : Le statut de la fiche ne correspon à aucun statut prévus')
      }
    },
  },
}
</script>

<style scoped></style>
