import axios from 'axios'

export default {
  namespaced: true,
  state: {
    postesAIM: [],
  },
  mutations: {
    SET_POSTES(state, _postes) {
      state.postesAIM = _postes
    },
  },
  getters: {
    getPostes(state) {
      return state.postesAIM
    },
  },
  actions: {
    fetchPostes({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/posteAIM`).then((response) => {
        commit('SET_POSTES', response.data)
      })
    },
  },
}
