<template>
  <v-select
    class="mr-4"
    v-model="selectedEtablissement"
    return-object
    :items="etablissements"
    item-text="raisonSociale"
    :label="label"
    :style="{ maxWidth: '260px' }"
    dense
    outlined
    :append-icon="selectedEtablissement && etablissements.length <= 1 ? '' : '$dropdown'"
    :disabled="selectedEtablissement && etablissements.length <= 1"
    hide-details
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'etablissement-picker',
  computed: {
    ...mapGetters({
      getEtablissements: 'etablissement/getSelectableEtablissements',
      getSelectedEtablissement: 'application/getSelectedEtablissement',
    }),
    etablissements() {
      return this.getEtablissements
    },
    selectedEtablissement: {
      get() {
        return this.getSelectedEtablissement
      },
      set(_etablissement) {
        this.selectEtablissement(_etablissement)
      },
    },
    label() {
      if (!this.selectedEtablissement) {
        return 'Etablissement'
      } else {
        return undefined
      }
    },
  },
  watch: {
    getEtablissements() {
      if (this.getEtablissements.length === 1) {
        this.selectEtablissement(this.getEtablissements[0])
      }
    },
  },
  methods: {
    ...mapActions({
      selectEtablissement: 'application/selectEtablissement',
    }),
  },
}
</script>

<style scoped></style>
