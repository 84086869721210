<template>
  <v-list-item class="py-0" @click="showDialog = true">
    <contrat-update-dialog
      v-if="contrat.heuresHebdo !== undefined"
      v-model="showDialog"
      :contratToUpdate="contrat"
      :employe="employe"
    />
    <v-list-item-content class="py-1">
      <v-list-item-title justify="center">
        <span>{{ contrat.type.acronyme }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>{{ sousTitre }} {{ dates }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="contrat.heuresHebdo !== undefined">
      <div>
        <v-chip outlined label> {{ contrat.heuresHebdo }}h par semaine </v-chip>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import contratUpdateDialog from './contrat-update-dialog.vue'

export default {
  name: 'contrat-card',
  components: {
    contratUpdateDialog,
  },
  props: {
    contrat: {
      type: Object,
      required: true,
    },
    employe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      getMembreById: 'membre/getMembreById',
    }),
    sousTitre() {
      let result = ''
      if (this.contrat && this.contrat.metier) {
        result += this.contrat.metier.libelle + ' '
      }

      if (this.contrat && this.contrat.remplaceMembreId) {
        result += '(remplace ' + this.getMembreById(this.contrat.remplaceMembreId).nomComplet + ')'
      }

      return result
    },
    dates() {
      if (this.contrat.dateFin) {
        return (
          'du ' +
          this.formatDateToDisplay(this.contrat.dateDebut) +
          ' au ' +
          this.formatDateToDisplay(this.contrat.dateFin)
        )
      } else {
        if (new Date(this.contrat.dateDebut) < new Date()) {
          return 'depuis le ' + this.formatDateToDisplay(this.contrat.dateDebut)
        } else {
          return 'à compter du ' + this.formatDateToDisplay(this.contrat.dateDebut)
        }
      }
    },
  },
}
</script>

<style scoped></style>
