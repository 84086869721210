<template>
  <s-page v-if="loaded">
    <template v-slot:lateral-panel>
      <s-lateral-panel>
        <v-text-field v-model="recherche" placeholder="Rechercher" hide-details dense clearable class="ma-2" />
        <perfect-scrollbar style="height: 100%" id="etablissements">
          <v-list>
            <v-list-item-group color="primary">
              <template v-for="(etablissement, index) in etablissementList">
                <etablissement-list-item :key="index + 1" :etablissement="etablissement" />
                <v-divider v-if="index < etablissementList.length - 1" :key="-(index + 1)"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </perfect-scrollbar>
        <etablissement-create-dialog v-if="isInRole('Administrateur')" />
      </s-lateral-panel>
    </template>
    <template v-slot:main-panel>
      <router-view />
    </template>
  </s-page>
</template>

<script>
import etablissementCreateDialog from '../../components/etablissement/etablissement-create-dialog'
import etablissementListItem from '../../components/etablissement/etablissement-list-item'
import { mapGetters } from 'vuex'
import { initialDataLoaded } from '../../store/modules/helpers'

export default {
  name: 'etablissement',
  components: {
    etablissementCreateDialog,
    etablissementListItem,
  },
  data() {
    return {
      recherche: null,
    }
  },
  computed: {
    ...initialDataLoaded,
    ...mapGetters({
      etablissements: 'etablissement/getEtablissements',
      selectedEtablissement: 'application/getSelectedEtablissement',
      isInRole: 'security/isInRole',
    }),
    loaded() {
      return initialDataLoaded && this.etablissements
    },
    etablissementList() {
      if (this.recherche === null || this.recherche === '') {
        return this.etablissements
      } else {
        return this.etablissements.filter(
          (et) => et.raisonSociale.toLowerCase().indexOf(this.recherche.toLowerCase()) <= -1
        )
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
