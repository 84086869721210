<template>
  <s-page>
    <template #main-panel> Fiche AIM n° {{ ficheAimId }} </template>
  </s-page>
</template>

<script>
export default {
  name: 'usagers',
  computed: {
    ficheAimId() {
      return this.$route.params.ficheAimId
    },
  },
}
</script>
