<template>
  <v-card :color="color" flat>
    <v-card-text>
      <div class="text-h4">{{ contrat.type.acronyme }}</div>
      <p class="text-body-2">{{ dates }}</p>
      <div class="text-body-1">{{ sousTitre }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'contrat-card',
  props: {
    contrat: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      formatDateToDisplay: 'calendrier/formatDateToDisplay',
      getMembreById: 'membre/getMembreById',
    }),
    sousTitre() {
      let result = ''
      if (this.contrat && this.contrat.metier) {
        result += this.contrat.metier.libelle + ' '
      }

      if (this.contrat && this.contrat.remplaceMembreId) {
        result += '(remplace ' + this.getMembreById(this.contrat.remplaceMembreId).nomComplet + ')'
      }

      return result
    },
    color() {
      switch (this.contrat.status) {
        case 'A venir':
          return 'info lighten-5'
        case 'En cours':
          return 'success lighten-5'
        case 'Terminé':
          return 'error lighten-5'
        default:
          return 'white lighten-5'
      }
    },
    dates() {
      if (this.contrat.dateFin) {
        return (
          'Du ' +
          this.formatDateToDisplay(this.contrat.dateDebut) +
          ' au ' +
          this.formatDateToDisplay(this.contrat.dateFin)
        )
      } else {
        if (new Date(this.contrat.dateDebut) < new Date()) {
          return 'Depuis le ' + this.formatDateToDisplay(this.contrat.dateDebut)
        } else {
          return 'À compter du ' + this.formatDateToDisplay(this.contrat.dateDebut)
        }
      }
    },
  },
}
</script>

<style scoped></style>
